import state from './moduleExchangeState.js'
import mutations from './moduleExchangeMutations.js'
import actions from './moduleExchangeActions.js'
import getters from './moduleExchangeGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
