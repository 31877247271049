import Vue from 'vue'
export default {

    /**
     * Set page title
     * @param state
     * @param title
     * @constructor
     */
    SET_TITLE(state, title)
    {
        // Store val
        Vue.set(state, 'title', title);
    },

   /**
    * Set page submenu
    * @param state
    * @param menu
    * @constructor
    */
    SET_TOOLBAR(state, tb)
    {
        // Store menu
        Vue.set(state, 'toolbar', tb);
    },

    /**
     * Set base icons
     * @param state
     * @param icons
     * @constructor
     */
    SET_BASE_ICONS(state, icons)
    {
        // Store base icons
        Vue.set(state.vars, 'baseIcons', icons);
    },

    /**
     * Set page title
     * @param state
     * @param title
     * @constructor
     */
    SET_VAR(state, {name, val})
    {
        // Store val
        Vue.set(state.vars, name, val);
    },

}
