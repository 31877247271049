import Vue from 'vue'
export default {

    /**
     * Add request to queue
     * @param state
     * @param campaigns
     * @constructor
     */
    ADD_SYNC_REQUEST(state, request) {

        // Generate new id
        request.id = state.requestsCount++;

        // Incease requests count
        Vue.set(state.syncRequests, request.id, request);
    },

    /**
     * Set requests
     * @param state
     * @param campaigns
     * @constructor
     */
    SET_SYNC_REQUESTS(state) {

        // Generate new id
        state.requestsCount = 0;

        // Incease requests count
        Vue.set(state, 'syncRequests', {});
    },


    /**
     * Remove request from queue
     * @param state
     * @param campaigns
     * @constructor
     */
    REMOVE_SYNC_REQUEST(state, requestID) {

        // Incease requests count
        Vue.delete(state.syncRequests, requestID);
    },

    /**
     * Set last update time
     * @param state
     * @param tm
     * @constructor
     */
    SET_LAST_UPDATE_TIME(state, tm)
    {
        // Set value
        state.lastUpdateTime = tm
    }
}
