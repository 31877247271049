import state from './moduleMiscState.js'
import mutations from './moduleMiscMutations.js'
import actions from './moduleMiscActions.js'
import getters from './moduleMiscGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
