import {api} from '../../utils/api'

/**
 * Donereward getters
 */
export default {

    // Get current donereward
    get: state => (i) => {

        // Return current state
        return state.donerewards[i];
    },

    // Get uncompleted tasks list for exact member
    list: state => (payload) => {

        // Check adult member
        //let isAdult = store.getters['family/isUserAdult'];

        // Return donerewards list
        return Object.values(state.donerewards).filter((donereward) => {

            // Check for member id
            return (donereward.member_id === payload.member_id && api.extractDate(donereward.client_time) === payload.date);
        });
    },

    /**
     * Get rewards groups
     */

    getRewardGroupsList: state => (id) =>
    {
        // Get reward
        let reward = state.donerewards[id];

        // No reward - no groups
        if(!reward) return "";

        // Result string
        let res = [];
        for(let g in reward.groups)
        {
            // Get score
            let sc = reward.groups[g];

            // Add result
            if(sc>0) res.push(window.app.$t("ttl"+g)+": "+sc);
        }

        // Return string
        return res.join(", ");
    },

    // Get groups list
    all: state => {

        // Return donerewards list
        return state.donerewards;
    },

}
