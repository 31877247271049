import state from './moduleTaskState.js'
import mutations from './moduleTaskMutations.js'
import actions from './moduleTaskActions.js'
import getters from './moduleTaskGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
