
export default {

    get: state => (i) => {

        // Return current state
        return state.bundles[i];
    },

    list: state => () => {
        return Object.values(state.bundles);
    },

}
