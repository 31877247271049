import state from './moduleFamilyState.js'
import mutations from './moduleFamilyMutations.js'
import actions from './moduleFamilyActions.js'
import getters from './moduleFamilyGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
