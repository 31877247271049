import {api} from '../../utils/api'

export default {
    create({ commit }, payload) {

        // Sync
        api.call(commit, 'post', '/api/admin/bundle', payload, 'SET_BUNDLES');

        // Create uuid
        return api.emptyPromise(payload);
    },

    loadList({ commit }) {
        // Sync
        api.call(commit, 'get', '/api/admin/bundle', '', 'SET_BUNDLES');
    },

    delete({commit}, payload) {
        commit('DELETE_BUNDLE', payload)

        api.call(commit, 'delete', '/api/admin/bundle/' + payload.id, '')
    }
}
