import {api} from '../../utils/api'

export default {

    /**
     * Create reward
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {

        // Create id
        payload.id = api.uuid();

        // Commit changes
        commit("SET_REWARDS", payload);

        // Sync
        api.sync('patch', '/api/admin/reward/' + payload.id, payload);

        // Create uuid
        return api.emptyPromise(payload);
    },

    /**
     * Update reward
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_REWARDS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/reward/' + payload.id, payload);
    },

    /**
     * Load rewards from server
     */
    loadList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/reward/' + familyID + '/list', null, "RESET_REWARDS");
    },


    /**
     * Load tasks from server
     */
    loadTemplates({ commit })
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/reward/templates-list', null, "SET_REWARDS");
    },

    /**
     * Delete reward
     * @param commit
     * @param payload
     */
    delete({ commit }, payload) {

        // Commit changes
        commit("DELETE_REWARDS", payload);

        // Create uuid
        return api.sync('delete', '/api/admin/reward/' + payload.id);
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("RESET_REWARDS", {});
    },


}
