import Vue from 'vue'
export default {


    /**
     * Set family changes
     * @param state
     * @param data
     * @constructor
     */
    SET_FAMILY(state, data) {
        // Store vars
        // eslint-disable-next-line no-prototype-builtins
        for (let fld in data) if (data.hasOwnProperty(fld)) Vue.set(state, fld, data[fld]);
    },

    /**
     * Logout
     * @param state
     * @param data
     * @constructor
     */
    LOGOUT(state) {
        // Reset family
        Vue.set(state, 'id', 0);
        Vue.set(state, 'title', '');
        Vue.set(state, 'member_id', 0);
    },

    /**
     * Set family member
     * @param state
     * @param data
     * @constructor
     */
    SET_FAMILY_MEMBER(state, member) {

        // Set member to family
        Vue.set(state, 'member_id', member);
    },

    SET_FAMILIES(state, families) {
        Vue.set(state, 'families', families);
    }

}
