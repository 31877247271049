/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./validators');

import {api} from './utils/api'
import {synchronizer} from './utils/synchronizer'
import axios from './axios'
import Vue from 'vue'
import Vuex from 'vuex';
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import router from './router-admin'
import store from './store/store'
import StorePlugin from "./utils/store-plugin";
import TranslationPlugin from "./utils/translate-plugin";
import VueMaterial from 'vue-material'
import '/src/sass/app.scss'
import '/src/sass/admin.scss'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from './App.vue'
import Paginate from 'vuejs-paginate'
import VCalendar from 'v-calendar';

require('./acl');

// Store Vue
window.Vue = Vue;

// Store api
Vue.prototype.$api = api;

// set axios
Vue.prototype.$http = axios;

Vue.prototype.$yearsTable = {
    0: '4-6',
    1: '7-10',
    2: '11-13',
    3: '14-17',
    4: '18+',
};

// init router
Vue.router = router;
Vue.use(StorePlugin);
Vue.use(TranslationPlugin);
Vue.use(VueRouter);
Vue.use(VueMaterial)
Vue.component('paginate', Paginate)
Vue.use(VCalendar)

// init http
Vue.use(VueAxios, axios);

// init validation
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

// init state store
Vue.use(Vuex);

// Load Index
Vue.component('app', () => import('./App.vue'));

// Create app
window.app = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store
});

// Send first request
synchronizer.start();
