import store from '../../store/store'

/**
 * Family getters
 */
export default {

    /**
     * Get current family
     * @param state
     * @returns {*}
     */
    get: state => {

        // Return current state
        return state;
    },

    /**
     * Get current member
     * @param state
     */
    getCurrentUser: state =>
    {
        // Return current member
        return store.getters['member/get'](state.member_id);
    },

    /**
     * Check is current member adult
     * @param state
     */
    isUserAdult: state =>
    {
        // Get user
        let u = store.getters['member/get'](state.member_id);

        // Return current member
        return u && u.family_member_type == 'adult';
    },

    /**
     * Check is current member adult
     * @param state
     */
    isLoggedInUser: state => (member_id) =>
    {
        // Return current member
        return state.member_id === member_id;
    },

    getFamilies: state => {
        return state.families
    }
}
