import { extend } from 'vee-validate';

// Add a rule.
import { required, email, min } from 'vee-validate/dist/rules';

// Add the required rule
extend('required', {
    ...required,
});

// add email rule
extend('email', {
    ...email,
});

// add email rule
extend('min', {
    ...min,
});


// google store validation
extend('store_link', value => {
    return value.indexOf('https://play.google.com') === 0 || value.indexOf('https://apps.apple.com') === 0;
});
