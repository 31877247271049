import store from '../store/store'
import {api} from "./api";

// Synchronize data with backend
export const synchronizer = {

    /**
     * Process starter
     */
    start()
    {
        // Start requests
        this.sendRequest();
    },

    /**
     * Send request for synchronization
     */
    sendRequest() {

        // Get request from queue
        let req = store.getters['system/getRequest'];

        // No requests - wait some time
        if(!req)
        {
            // Send update request

            // Try again after 1 sec
            setTimeout(() => {
                this.sendRequest()
            }, 5000 );
            return;
        }

        // Call api method
        api.call(false, req.method, req.path, req.payload).then(() => {
            // Delete request from queue
            store.commit("system/REMOVE_SYNC_REQUEST", req.id);

            // Send new request
            setTimeout(() => {
                this.sendRequest();
            }, 1);

        }).catch(() => {
            // Send again after 10 seconds
            setTimeout(() => {
                this.sendRequest();
            }, 10000);
        })
    }

};
