import Vue from 'vue'
export default {

    /**
     * Set translation
     * @param state
     * @param payload
     * @constructor
     */
    SET_TRANSLATIONS(state, translations)
    {
        // Tasks can be a single task or  list
        if (!Array.isArray(translations)) translations = [translations];

        // Save each  from list to state
        translations.forEach(i => {
            Vue.set(state.translations, i.id+"/"+i.language, i);
        });
    },

    /**
     * Reset translation
     * @param state
     * @param payload
     * @constructor
     */
    RESET_TRANSLATIONS(state, translations)
    {
        // Reset current
        state.translations = {};

        // Calc children
        this.commit('translation/SET_TRANSLATIONS', translations);
    },

  /**
     * Set languages
     * @param state
     * @param payload
     * @constructor
     */
    SET_LANGUAGES(state, languages)
    {
        // Tasks can be a single task or  list
        if (!Array.isArray(languages)) languages = [languages];

        // Save each  from list to state
        languages.forEach(i => {
            Vue.set(state.languages, i.id, i);
        });
    },

    /**
     * Set current language
     * @param state
     * @param payload
     * @constructor
     */
    SET_LANGUAGE(state, lang)
    {
        // Store val
        Vue.set(state, 'currentLanguage', lang);
    },

    /**
     * Set current country
     * @param state
     * @param payload
     * @constructor
     */
    SET_COUNTRY(state, lang)
    {
        // Store val
        Vue.set(state, 'currentCountry', lang);
    },

    /**
     * Set current locaale
     * @param state
     * @param locale
     * @constructor
     */
    SET_LOCALE(state, locale)
    {
        // Set new
        this.commit('translation/SET_COUNTRY', locale.country);
        this.commit('translation/SET_LANGUAGE', locale.language);
    },

    /**
     * Delete translation
     * @param state
     * @param alias
     * @constructor
     */
    DELETE_TRANSLATION(state, alias)
    {
        // Delete all items with alias
        Object.values(state.translations).map( (i) => {
            // Delete item
            if (i.id === alias) {
                Vue.delete(state.translations, i.id+"/"+i.language);
            }
        });
    },

}
