/**
 * Family state
 */

export default {
    currencies: [],
    applications: {},
    redeemApplications: {},
    rateSteps: {
        1: 1,
        10: 10,
        100: 100,
        1000: 1000,
        10000: 10000
    }
}
