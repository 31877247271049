export default {
    list: state => {
        return Object.values(state.notifications).sort((a,b) => {
            return b.id - a.id
        });
    },

    get: state => (id) => {
        return state.notifications[id]
    }
}
