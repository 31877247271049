import {api} from '../../utils/api'

export default {

    /**
     * Create family
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {
        // Sync
        return api.call(commit,'post', '/api/admin/family', payload).then((res) =>
        {
            // Set family params
            commit("family/SET_FAMILY", res.family, { root: true });

            // Set member
            commit("member/SET_MEMBERS", res.member, { root: true });
        });
    },

    /**
     * Loogut
     * @param commit
     * @param payload
     */
    logout({ commit }) {

        // Commit changes
        commit("LOGOUT");

        commit("member/RESET_MEMBERS", {}, { root: true });
        commit("task/RESET_TASKS", {}, { root: true });
        commit("reward/RESET_REWARDS", {}, { root: true });
        commit("completedtask/RESET_COMPLETEDTASKS", {}, { root: true });

        // Return promise
        return api.emptyPromise(false);

    },


    /**
     * Set current member
     * @param commit
     * @param payload
     */
    setMember({ commit }, member) {

        // Set member to family
        commit("SET_FAMILY_MEMBER", member);

        // Return promise
        return api.emptyPromise(false);
    },


    /**
     * Load member
     * @param commit
     * @param payload
     */
    load({ commit }, payload) {

        // Create uuid
        return api.call( commit,'get', '/api/admin/family/' + payload, null, "SET_FAMILY");
    },


    /**
     * Update family
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_FAMILY", payload);

        // Sync
        return api.sync('patch', '/api/admin/family/' + payload.id, payload);

    },

    list({commit}, payload) {
        let page = payload?.page || 1
        let perPage = payload.per_page || 15
        let sortParams = ''
        if (payload.sort_params && Object.keys(payload.sort_params).length) {
            let key = Object.keys(payload.sort_params)[0]
            sortParams = '&sort=' + key
            sortParams += '&position=' + payload.sort_params[key]
        }
        return api.call(commit,
            'get',
            '/api/admin/family/list?page=' + page + '&per_page=' + perPage + sortParams, payload,
            'SET_FAMILIES', '', '', true);
    }


}
