export default {

    // Updates member info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        for (const property of Object.keys(payload)) {
            if (payload[property] != null) {
                state.AppActiveUser[property] = payload[property];
            }
        }
    },
}
