/**
 * Task getters
 */
export default {

    // Get current task
    get: state => (i) => {

        // Return current state
        return state.tasks[i];
    },

    // Get tasks list
    list: state => (group) => {

        // Tasks list
        let tList = {};

        // Rearrange list
        Object.values(state.tasks).
        filter((task) => task.group === group).
        sort((a, b) => (a.family_id ? a.family_id.length : 0) - (b.family_id ? b.family_id.length : 0 ) ).
        map((i) => {
            // Get title
            let ttl = !i.family_id ? window.app.$t(i.title) : i.title;

            tList[ttl] = i;
        })

        // Return tasks list
        return Object.values(tList);
    },

    // Get template list
    templateList: state => (group) => {

        // Return completedtasks list
        return Object.values(state.tasks).filter((task) => (task.group === group && task.family_id===null));
    },


    // Get tasks list for exact member
    memberList: state => (payload) => {

        // Return tasks list
        return Object.values(state.tasks).filter((task) => task.assignees ? task.assignees.includes(payload.member_id) && task.group == payload.group : false);
    },


    // Get tasks list for exact member
    memberGroups: state => (member_id) => {

        // Return the full set of groups
        if(member_id===false)
        {
            // Return groups list
            return Object.keys(state.groups).map( (i) => ('gr_' + i) );
        }

        // Get active tasks groups list
        let groups, tasks = Object.values(state.tasks).filter((task) =>  ( !member_id || (task.assignees ? task.assignees.includes(member_id) : false) ) );

        // Check for tasks list
        if(tasks && (groups = Object.values(tasks).map( (i) => ('gr_' + i.group) )) )
        {
            return groups;
        }

        // Nothing
        return [];
    },


    // Get groups list
    groups: state => {

        // Return tasks list
        return state.groups;
    },

    /**
     * get group id
     * @param state
     * @returns {function(...[*]=)}
     */
    getTaskGroup: state => (id) =>
    {
        if(!state.tasks[id]) return "none";

        // Get task group
        return state.groups[state.tasks[id].group];
    }

}
