import state from './moduleSystemState.js'
import mutations from './moduleSystemMutations.js'
import actions from './moduleSystemActions.js'
import getters from './moduleSystemGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
