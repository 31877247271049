export default {
    // get logged member state
    currentUser: state => {
        return state.AppActiveUser;
    },

    // Check role
    checkRole: state => (role) =>
    {
        // Check
        return state.AppActiveUser && state.AppActiveUser.id ? state.AppActiveUser.roles.includes(role) : false;
    }
}
