import {api} from '../../utils/api'

export default {

    /**
     * Create donereward
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {

        // Create new done reward item
        let dR = payload;

        // Set parent reword id to the
        dR.reward_id = payload.id;

        // Set client time
        dR.client_time = api.msDateTime(new Date());

        // Create id
        dR.id = api.uuid();

        // Commit changes
        commit("SET_DONEREWARDS", dR);

        // Sync
        api.sync('post', '/api/admin/donereward', dR);

        // Create uuid
        return api.emptyPromise(payload);
    },



    /**
     * Update donereward
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_DONEREWARDS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/donereward/' + payload.id, payload);
    },

    /**
     * Load donerewards from server
     */
    loadList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/donereward/' + familyID + '/list', null, "SET_DONEREWARDS");
    },

    /**
     * Delete donereward
     * @param commit
     * @param payload
     */
    delete({ commit }, payload) {

        // Commit changes
        commit("DELETE_DONEREWARDS", payload);

        // Create uuid
        return api.sync('delete', '/api/admin/donereward/' + payload.id);
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("RESET_DONEREWARDS", {});
    },

}
