export default {

    /**
     * Get request to be send
     * @param state
     * @returns request
     */
    getRequest: state => {

        // Convert to array
        let arr = Object.values(state.syncRequests);

        // Get request
        return arr && arr.length ? arr[0] : false;
    },

    /**
     * Get last update
     * @returns {number}
     */
    getLastUpdateTime: state => {

        // Get las ttime
        return state.lastUpdateTime ? state.lastUpdateTime : 0;
    }

}
