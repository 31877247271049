import jwt from "../../auth"
import axios from "../../axios";

export default {

    // JWT
    login({ commit }, payload) {

        return new Promise((resolve,reject) => {
            jwt.login(payload.email, payload.password)
                .then(response => {

                    // If there's member data in response
                    if(response.data) {
                        // Set accessToken
                        localStorage.setItem("accessToken", response.headers.authorization);

                        // Update member details
                        commit('UPDATE_USER_INFO', response.data.data);

                        // Set bearer token in axios
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.headers.authorization;

                        resolve(response.data.data)
                    }else {
                        reject({message: "Wrong Email or Password"})
                    }

                })
                .catch(error => { reject(error) })
        })
    },

    logout({commit}) {
        return new Promise((resolve) => {
            // logout app
            jwt.logout();
            // Set accessToken
            localStorage.removeItem("accessToken");
            // Update member details
            commit('auth/UPDATE_USER_INFO', {id: 0, name:'', mail:''}, {root: true});

            resolve();
        });
    },

    //
    register({ commit }, payload) {

        const { name, email, password, confirmPassword } = payload

        return new Promise((resolve,reject) => {

            // Check confirm password
            if(password !== confirmPassword) {
                reject({message: "Password doesn't match. Please try again."})
            }

            jwt.registerUser(name, email, password)
                .then(response => {
                    // Redirect User
                    // eslint-disable-next-line no-undef
                    router.push(router.currentRoute.query.to || '/')

                    // Update data in localStorage
                    localStorage.setItem("accessToken", response.data.accessToken);
                    commit('UPDATE_USER_INFO', response.data.data, {root: true});

                    resolve(response)
                })
                .catch(error => { reject(error) })
        })
    },

    refresh() {
        return new Promise((resolve) => {
            jwt.refreshToken().then(response => { resolve(response) })
        })
    },

    // set member data
    setUser({commit}, data) {
        commit('UPDATE_USER_INFO', data);
    }
}
