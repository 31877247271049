import store from "../store/store";

// Plugin description
export default {

    // install translate plugin
    install(Vue) {

        /**
         * Dispatch action
         * @returns {Promise<unknown>}
         * @param str
         */
        Vue.prototype.$t = function(str) {

            // Get translation
            let trans = store.getters['translation/getCurrent'](str);

            // Looking for translation
            return trans ? trans : ("["+str+"]");
        };
    }
};
