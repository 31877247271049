import {api} from '../../utils/api'

export default {

    /**
     * Create task
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {

        // Create id
        payload.id = api.uuid();

        // Commit changes
        commit("SET_TASKS", payload);

        // Sync
        api.sync('patch', '/api/admin/task/' + payload.id, payload);

        // Create uuid
        return api.emptyPromise(payload);
    },

    /**
     * Update task
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_TASKS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/task/' + payload.id, payload);
    },

    /**
     * Load tasks from server
     */
    loadList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/task/' + familyID + '/list', null, "RESET_TASKS");
    },

    /**
     * Load tasks from server
     */
    loadTemplates({ commit })
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/task/templates-list', null, "SET_TASKS");
    },

    /**
     * Delete task
     * @param commit
     * @param payload
     */
    delete({ commit }, payload) {

        // Commit changes
        commit("DELETE_TASKS", payload);

        // Create uuid
        return api.sync('delete', '/api/admin/task/' + payload.id);
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("RESET_TASKS", {});
    },

}
