<template>
    <router-view></router-view>
</template>
<script>
    export default {
        created() {
            // Init task groups
            this.$store.commit('task/SET_GROUPS', {
                learn: {title: "ttllearn", icon: "learn"},
                home: {title: "ttlhome", icon: "home"},
                sport: {title: "ttlsport", icon: "sport"},
                other: {title: "ttlother", icon: "other"},
                behavior: {title: "ttlbehavior", icon: "behavior"},
            });

            // Init reward groups
            this.$store.commit('reward/SET_GROUPS', {
                entertainment: {title: "ttlentertainment", icon: "entertainment"},
                food: {title: "ttlfood", icon: "food"},
                shopping: {title: "ttlshopping", icon: "shopping"},
                travel: {title: "ttltravel", icon: "travel"},
                games: {title: "ttlgames", icon: "games"},
            });

            //Init base icons
            this.$dispatch("misc/loadBaseIcons");

            // Load translations
            this.$dispatch("translation/loadAll");
        }
    }
</script>
