import Vue from 'vue'

export default {
    SET_BUNDLES(state, bundles) {
        // Nothing to do
        if (!bundles) return;

        if (!state.bundles) state.bundles = {};

        if (!Array.isArray(bundles)) bundles = [bundles];

        // Save each donereward from list to state
        bundles.forEach(i => {

            // Create object if absent
            if(!state.bundles[i.id])
            {
                // Set whole object
                Vue.set(state.bundles, i.id, i);
            }
            else
            {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for(let fld in i) if(i.hasOwnProperty(fld)) Vue.set(state.bundles[i.id], fld, i[fld]);
            }
        });
    },

    DELETE_BUNDLE(state, bundles) {
        if (!Array.isArray(bundles)) bundles = [bundles];

        // Save each reward from list to state
        bundles.forEach(i => {
            Vue.delete(state.bundles, i.id);
        });
    }
}
