import Vue from 'vue'
export default {

    /**
     * Set completedtasks
     * @param state
     * @param data
     * @constructor
     */
    SET_COMPLETEDTASKS(state, completedtasks) {

        // Nothing to do
        if (!completedtasks) return;

        // Create completedtasks if absent
        if (!state.completedtasks) state.completedtasks = {};

        // Completedtasks can be a single completedtask or completedtasks list
        if (!Array.isArray(completedtasks)) completedtasks = [completedtasks];

        // Save each completedtask from list to state
        completedtasks.forEach(i => {

            // Create object if absent
            if(!state.completedtasks[i.id])
            {
                // Set whole object
                Vue.set(state.completedtasks, i.id, i);
            }
            else
            {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for(let fld in i) if(i.hasOwnProperty(fld)) Vue.set(state.completedtasks[i.id], fld, i[fld]);
            }
        });
    },


    /**
     * Reset completedtasks
     * @param state
     * @param data
     * @constructor
     */
    RESET_COMPLETEDTASKS(state, completedtasks) {

        // Reset list
        state.completedtasks = {};

        // Nothing to do
        if (!completedtasks) return;

        // Completedtasks can be a single completedtask or completedtasks list
        if (!Array.isArray(completedtasks)) completedtasks = [completedtasks];

        // Save each completedtask from list to state
        completedtasks.forEach(i => {

            // Set whole object
            Vue.set(state.completedtasks, i.id, i);
        });
    },


    /**
     * Delete completedtask
     * @param state
     * @param completedtask
     * @constructor
     */
    DELETE_COMPLETEDTASKS(state, completedtasks) {

        // Completedtasks can be a single completedtask or completedtasks list
        if (!Array.isArray(completedtasks)) completedtasks = [completedtasks];

        // Save each completedtask from list to state
        completedtasks.forEach(i => {
            Vue.delete(state.completedtasks, i.id);
        });

    },

    /**
     * Set groups
     * @param state
     * @param groups
     * @constructor
     */
    SET_GROUPS(state, groups)
    {
        Vue.set(state, 'groups', groups);
    }
}
