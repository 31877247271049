/**
 * Family state
 */

export default {

    // Rewards list
    rewards: {},
    groups: {}
}
