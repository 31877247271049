
/**
 * Member getters
 */
export default {

    // Get current member
    get: state => (i) => {

        // Return current state
        return state.members[i];
    },


    // Get members list
    list: state => {

        // Return members list
        return state.members;
    },

}
