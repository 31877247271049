import actions from './modulePushNotificationsActions'
import getters from "./modulePushNotificationsGetters";
import state from "./modulePushNotificationsState";
import mutations from "./modulePushNotificationsMutations";


export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters,
}
