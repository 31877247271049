export default {

    /**
     * Get translation
     * @param state
     * @returns request
     */
    get: state => (id) => {
        // Get translation
        return state.translations[id] ? state.translations[id].value : "";
    },

    /**
     * Check translation exists
     * @param state
     * @returns request
     */
    exists: state => (id) => {

        // Default result
        let exists = false;

        // Check keys
        Object.keys(state.translations).map((key) =>
        {
            if(key.indexOf(id+"/") !== -1) exists = true;
        });

        // Existance
        return exists;
    },

    /**
     * Get list of values
     * @param state
     */
    list: state => (gr) =>
    {
        return Object.values(state.translations).filter((i) => i.group === gr);
    },

    /**
     * Get groups of values
     * @param state
     */
    groups: state =>
    {
        // Init list
        let grps = {common: "common"};

        // Extract groups
        Object.values(state.translations).map((i) =>
        {
            // Store group
            grps[i.group] = i.group;
        });

        // Return groups
        return Object.values(grps);
    },

    /**
     * Get list of languages
     * @param state
     */
    languages: state =>
    {
        return Object.values(state.languages);
    },

    /**
     * Get unique aliases
     * @param state
     */
    aliases: state => (group) =>
    {
        // Aliases
        let aliases = {};

        // Map aliases
        Object.values(state.translations).filter((i) => i.group == group).map((i) =>
        {
            aliases[i.id] = 1;
        });

        // Return aliases
        return Object.keys(aliases);
    },

    /**
     * Get current translation
     * @param state
     * @returns request
     */
    getCurrent: state => (id) => {

        // Get result
        let res = state.translations[id+"/"+state.currentLanguage];

        // Get translation
        return res ? res.value : "";
    },

    /**
     * Get currrent language
     * @returns {numbr}
     */

    currentLanguage: state => {

        // Return submenu
        return state.currentLanguage ? state.currentLanguage : "en";
    },

    /**
     * Get current country
     */

    currentCountry: state => {

        // Return submenu
        return state.currentCountry ? state.currentCountry : false;
    },

    getLanguage: state => (id) => {
        console.log(state.languages)
        return state.languages[id]
    }
}
