import auth from './auth'
import axios from "./axios";
import store from "./store/store";
import router from './router-admin'
import _ from 'lodash'

// init member from token
let accessToken = localStorage.getItem('accessToken');

if(accessToken) {
    // set token as header
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

router.beforeEach((to, from, next) => {
    // get member auth state
    let currentUser = store.getters['auth/currentUser'];

    // not authorized, but token available
    if(!currentUser.id && accessToken) {
        return auth.userData().then(res => {
            // update member state
            store.dispatch('auth/setUser', res);

            // check roles
            if(!to.meta.roles || (to.meta.roles && _.intersection(to.meta.roles, res.roles).length)) next();
            else next('404');
        }).catch(() => {
            localStorage.removeItem('accessToken');
            location.reload();
        });
    }

    // check roles
    if(!to.meta.roles || (to.meta.roles && _.intersection(to.meta.roles, currentUser.roles).length)) next();
    else next('404');
});
