import Vue from 'vue'
import axios from "../axios";
import {uuid} from 'vue-uuid';

export const api = {

    getBaseUrl() {
        return process.env.VUE_APP_API_PATH
    },

    /**
     * call api method
     * @param commit
     * @param method
     * @param path
     * @param payload
     * @param mutation
     * @param mock_data
     * @param content_type
     */
    sync(method, path, payload) {
        // Add request to the sync queue
        window.app.$dispatch("system/addSyncRequest", {method: method, path: path, payload: this.unreact(payload)});
    },

    /**
     * call api method
     * @param commit
     * @param method
     * @param path
     * @param payload
     * @param mutation
     * @param mock_data
     * @param content_type
     * @param paginate
     */
    call(commit, method, path, payload, mutation, mock_data, content_type = '', paginate = false) {

        // Init config
        let config = content_type ? {headers: {'Content-Type': content_type}} : {};

        // Create new promise
        return new Promise((resolve, reject) => {
            let promise = null;
            switch (method) {
                case 'get':
                    promise = axios.get(path);
                    break;
                case 'post':
                    promise = axios.post(path, payload, config);
                    break;
                case 'patch':
                    promise = axios.patch(path, payload);
                    break;
                case 'delete':
                    promise = axios.delete(path);
                    break;
            }
            // bad method
            if (!promise) throw 'Unknown REST method: ' + method;

            // resolve api call
            promise.then(res => {

                let resData;

                if (paginate) {
                    resData = {items: res.data?.data, curPage: res.data?.meta?.current_page, total: res.data?.meta?.last_page}
                } else {
                    // Get response data
                    resData = res.data.data ? res.data.data : res.data;
                }


                // Resolve data
                if (res.data.status && res.data.status == 'error') {

                    // Process error
                    reject(res.data);
                } else {
                    // Apply esuresponse
                    //let resData = payload ? Object.assign(payload, respData) : respData;
                    if (mutation) commit(mutation, resData ? resData : payload);

                    // Resolve it
                    resolve(resData);
                }

            }).catch(err => {

                reject(err);
            })
        });
    },

    /**
     * Create not reactive copy of object
     * @param data
     * @returns {any}
     */
    unreact(data) {

        // Return empty object
        if (!data) return false;

        // Unreact object
        return JSON.parse(JSON.stringify(data));
    },

    /**
     * Create reactive copy of object
     * @param data
     * @returns {any}
     */
    reactObject(obj) {

        // Register in vm
        var tmpVm = new Vue({data: {obj}});

        // Return object
        return tmpVm.obj;
    },

    /**
     * Generate uid
     * @returns {never|*}
     */
    uuid() {

        // Calc value
        let val = uuid.v1();

        // Return uid
        return val;
    },


    /**
     * Empty promise
     */
    emptyPromise(data) {
        return new Promise((resolve) => {
            resolve(data);
        })
    },


    /**
     * Merge objects
     * @param o1
     * @param o2
     */
    mergeObjects(orig, nw) {
        // Init new object if empty
        if (!orig) orig = {};

        // Process each value
        for (let ind in nw) {
            // Apply new data to the original object
            if (nw[ind] !== undefined && nw[ind] !== null) Vue.set(orig, ind, nw[ind]);
        }

        // Return original
        return orig;
    },

    /**
     * Convert mysql time to unixtime
     * @param tm
     * @returns {number}
     */
    msTime2Unix(tm) {
        // Parse time
        var t = tm.split(/[- :]/);

        // Apply each element to the Date function
        var d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

        // Return unix time
        return d.getTime() / 1000;
    },


    /**
     * Convert javascript to unixtime
     * @param tm
     * @returns {number}
     */
    unixtime(tm) {
        // Return unix time
        return tm / 1000;
    },

    /**
     * Scan barcode
     */
    scanBarcode(promptMsg, success, cancel, fail) {
        // Call the scanner
        // eslint-disable-next-line no-undef
        cordova.plugins.barcodeScanner.scan(function (res) {
                // Check for canceel
                if (res.cancelled) cancel(res); else success(res);
            }, fail,
            {
                preferFrontCamera: true,
                prompt: promptMsg,
            });
    },

    /**
     * Adding leading zeros
     * @param num
     * @param size
     * @returns {string}
     */
    pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    },

    /**
     * Get date and time format
     * @param ms
     */
    msDateTime(ms)
    {
        let a = new Date(ms);
        let year = a.getFullYear();
        let month = a.getMonth() + 1;
        let date = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        let time = year + '-' + api.pad(month,2) + '-' + api.pad(date,2) + ( hour || min || sec ? (' ' + api.pad(hour,2) + ':' + api.pad(min,2) + ':' + api.pad(sec,2) ) : "" );
        return time;
    },

    /**
     * Extract time from date time
     * @param tm
     */
    extractTime(tm)
    {
        // check for tm
        if(!tm || !tm.length) return "";

        // Extract parts
        let dpr = tm.split(" ");

        // Return time
        return dpr.length > 1 ? dpr[1] : "";
    },

    /**
     * Extract date from date time
     * @param tm
     */
    extractDate(tm)
    {
        // check for tm
        if(!tm || !tm.length) return "";

        // Extract parts
        let dpr = tm.split(" ");

        // Return time
        return dpr.length > 0 ? dpr[0] : "";
    }
};
