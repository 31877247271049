import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
import moduleAuth from './auth/moduleAuth.js'
import moduleFamily from './family/moduleFamily.js'
import moduleSystem from './system/moduleSystem'
import moduleMember from './member/moduleMember'
import moduleReward from './reward/moduleReward'
import moduleTask from './task/moduleTask'
import moduleMisc from './misc/moduleMisc'
import moduleCompletedTask from './completed-task/moduleCompletedTask'
import moduleDoneReward from './done-reward/moduleDoneReward'
import moduleTranslation from './translation/moduleTranslation'
import modulePushNotifications from "./push-notifications/modulePushNotifications";
import moduleBundles from "./bundle/moduleBundle";
import moduleExchange from "./exchange/moduleExchange";

// Setup vuex
Vue.use(Vuex);

const vuexPersist = new VuexPersist({
    key: 'ctd',
    storage: window.localStorage
})

// Create store
export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        family: moduleFamily,
        system: moduleSystem,
        member: moduleMember,
        reward: moduleReward,
        task: moduleTask,
        completedtask: moduleCompletedTask,
        donereward: moduleDoneReward,
        translation: moduleTranslation,
        misc: moduleMisc,
        notifications: modulePushNotifications,
        bundles: moduleBundles,
        exchange: moduleExchange
    },
    plugins: [vuexPersist.plugin],
    strict: process.env.NODE_ENV !== 'production'
})
