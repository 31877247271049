import axios from "./axios"

export default {
    login(email, pwd) {
        return axios.post("/api/auth/login", {email: email, password: pwd})
    },
    logout() {
        return axios.post("/api/auth/logout")
    },
    registerUser(name, email, pwd) {
        return axios.post("/api/auth/register", {name: name, email: email, password: pwd})
    },
    refreshToken() {
        return axios.post("/api/auth/refresh-token", {accessToken: localStorage.getItem("accessToken")})
    },
    userData() {
        return new Promise(function(resolve, reject) {
            axios.get("/api/auth/member").then(res => {resolve(res.data.data);}).catch(err => {reject(err);})
        });
    }
}
