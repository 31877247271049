export default {
    /**
     * Add ajax requst
     * @param commit
     * @returns {*|Promise|Promise<any>}
     */
    addSyncRequest({commit}, req) {
        commit("ADD_SYNC_REQUEST", req);
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("SET_SYNC_REQUESTS", {});
    },

    /**
     * Set update time
     */
    setUpdateTime({commit}, tm)
    {
        // Store time
        commit("SET_LAST_UPDATE_TIME", tm);
    }
}
