import Vue from 'vue'

export default {

    /**
     * Set donerewards
     * @param state
     * @param data
     * @constructor
     */
    SET_DONEREWARDS(state, donerewards) {

        // Nothing to do
        if (!donerewards) return;

        // Create donerewards if absent
        if (!state.donerewards) state.donerewards = {};

        // Donerewards can be a single donereward or donerewards list
        if (!Array.isArray(donerewards)) donerewards = [donerewards];

        // Save each donereward from list to state
        donerewards.forEach(i => {

            // Create object if absent
            if(!state.donerewards[i.id])
            {
                // Set whole object
                Vue.set(state.donerewards, i.id, i);
            }
            else
            {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for(let fld in i) if(i.hasOwnProperty(fld)) Vue.set(state.donerewards[i.id], fld, i[fld]);
            }
        });
    },


    /**
     * Reset donerewards
     * @param state
     * @param data
     * @constructor
     */
    RESET_DONEREWARDS(state, donerewards) {

        // Reset list
        state.donerewards = {};

        // Nothing to do
        if (!donerewards) return;

        // Donerewards can be a single donereward or donerewards list
        if (!Array.isArray(donerewards)) donerewards = [donerewards];

        // Save each donereward from list to state
        donerewards.forEach(i => {

            // Set whole object
            Vue.set(state.donerewards, i.id, i);
        });
    },


    /**
     * Delete donereward
     * @param state
     * @param donereward
     * @constructor
     */
    DELETE_DONEREWARDS(state, donerewards) {

        // Donerewards can be a single donereward or donerewards list
        if (!Array.isArray(donerewards)) donerewards = [donerewards];

        // Save each donereward from list to state
        donerewards.forEach(i => {
            Vue.delete(state.donerewards, i.id);
        });

    },

    /**
     * Set groups
     * @param state
     * @param groups
     * @constructor
     */
    SET_GROUPS(state, groups)
    {
        Vue.set(state, 'groups', groups);
    }
}
