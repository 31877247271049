import Vue from 'vue'

export default {


    /**
     * Set rewards
     * @param state
     * @param applications
     * @constructor
     */
    SET_APPLICATIONS(state, applications) {

        // Nothing to do
        if (!applications) return;

        if (!state.applications) state.applications = {};

        if (!Array.isArray(applications)) applications = [applications];

        applications.forEach(i => {

            // Create object if absent
            if (!state.applications[i.id]) {
                // Set whole object
                Vue.set(state.applications, i.id, i);
            } else {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for (let fld in i) if (i.hasOwnProperty(fld)) Vue.set(state.applications[i.id], fld, i[fld]);
            }
        });
    },

    SET_REDEEM_APPLICATIONS(state, redeemApplications) {

        // Nothing to do
        if (!redeemApplications) return;

        if (!state.redeemApplications) state.redeemApplications = {};

        if (!Array.isArray(redeemApplications)) redeemApplications = [redeemApplications];

        redeemApplications.forEach(i => {

            // Create object if absent
            if (!state.redeemApplications[i.id]) {
                // Set whole object
                Vue.set(state.redeemApplications, i.id, i);
            } else {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for (let fld in i) if (i.hasOwnProperty(fld)) Vue.set(state.redeemApplications[i.id], fld, i[fld]);
            }
        });
    },
    /**
     * Reset rewards
     * @param state
     * @param applications
     * @constructor
     */
    RESET_APPLICATIONS(state, applications) {

        // Create rewards if absent
        // state.rewards = {};
        Vue.set(state, 'applications', {})
        // Nothing to do
        if (!applications) return;

        // Rewards can be a single reward or rewards list
        if (!Array.isArray(applications)) applications = [applications];

        // Save each reward from list to state
        applications.forEach(i => {
            // Set whole object
            Vue.set(state.applications, i.id, i);
        });
    },


    /**
     * Delete reward
     * @param state
     * @param applications
     * @constructor
     */
    DELETE_APPLICATIONS(state, applications) {

        if (!Array.isArray(applications)) applications = [applications];

        applications.forEach(i => {
            Vue.delete(state.applications, i.id);
        });

    },


    /**
     * Set groups
     * @param state
     * @param currencies
     * @constructor
     */
    SET_CURRENCIES(state, currencies) {
        state.currencies = []
        Vue.set(state, 'currencies', currencies);
    }
}
