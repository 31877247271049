import state from './moduleBundleState.js'
import mutations from './moduleBundleMutations.js'
import actions from './moduleBundleActions.js'
import getters from './moduleBundleGetters.js'

export default {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
