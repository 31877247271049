import {api} from '../../utils/api'

export default {

    /**
     * Create completedtask
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {

        // Create id
        payload.id = api.uuid();

        // Set client time
        payload.client_time = api.msDateTime(new Date());

        // Commit changes
        commit("SET_COMPLETEDTASKS", payload);

        // Sync
        api.sync('patch', '/api/admin/completedtask/' + payload.id, payload);

        // Create uuid
        return api.emptyPromise(api.unreact(payload));
    },

    /**
     * Update completedtask
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_COMPLETEDTASKS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/completedtask/' + payload.id, payload);
    },

    /**
     * Load completedtasks from server
     */
    loadList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/completedtask/' + familyID + '/list', null, "SET_COMPLETEDTASKS");
    },

    /**
     * Delete completedtask
     * @param commit
     * @param payload
     */
    delete({ commit }, payload) {

        // Commit changes
        commit("DELETE_COMPLETEDTASKS", payload);

        // Create uuid
        return api.sync('delete', '/api/admin/completedtask/' + payload.id);
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("RESET_COMPLETEDTASKS", {});
    },

}
