import {api} from '../../utils/api'

export default {

    /**
     * Load translations
     * @param commit
     * @param title
     * @returns {*|Promise|Promise<any>}
     */
    load({ commit }, aliases) {

        // Get current language
        let lng = window.$store.getters['translation/currentLanguage'];

        // Load translations list
        return api.call(commit,'get', '/api/admin/translation/load/' + lng + "/" + aliases.join(","), null, "SET_TRANSLATIONS");
    },

    /**
     * Load translations
     * @param commit
     * @param title
     * @returns {*|Promise|Promise<any>}
     */
    loadLocale({ commit }) {
        // Load translations list
        return api.call(commit,'get', '/api/admin/translation/locale', null, "SET_LOCALE");
    },

    /**
     * Load all translations
     * @param commit
     * @param title
     * @returns {*|Promise|Promise<any>}
     */
    loadAll({ commit }) {

        // Load translations list
        return api.call(commit,'get', '/api/admin/translation/load/', null, "RESET_TRANSLATIONS");
    },

    /**
     * Load languages
     * @param commit
     * @param title
     * @returns {*|Promise|Promise<any>}
     */
    loadLanguages({ commit }) {

        // Load languages
        return api.call(commit,'get', '/api/admin/languages/', null, "SET_LANGUAGES");
    },

    updateLanguage({commit}, payload) {
        return api.call(commit,'patch', '/api/admin/language/' + payload.id, payload, "SET_LANGUAGES");
    },


    /**
     * Create new translation
     * @param commit
     * @param payload
     * @returns {Promise<any>}
     */
    set({ commit }, payload) {
        commit('SET_TRANSLATIONS', payload)
        // Store translation
        return api.call(commit, 'post', '/api/admin/translation', payload);
    },

    /**
     * Delete alias
     * @param commit
     * @param alias
     * @returns {Promise<any>}
     */
    delete({ commit }, alias) {

        // Store translation
        return api.call(commit, 'delete', '/api/admin/translation/' + alias, false, 'DELETE_TRANSLATION');
    }
}
