import VueRouter from 'vue-router'
import store from "./store/store"

// Routes
const routes = [

    // public area
    {
        path: '/admin',
        component: () => import('./templates/Admin'),
        beforeEnter: function (to, from, next) {
            let user = store.getters["auth/currentUser"]
            if (user.id) {
                next()
            } else {
                next({name: "login"})
            }
        },
        children: [
            {
                path: '',
                component: () => import('./pages/Admin/Home'),
            },
            {
                path: '/logout',
                name: 'logout',
                meta: {
                    auth: true
                },
                beforeEnter: (to, from, next) => {
                    // logout member
                    store.dispatch('auth/logout').then(() => {
                        next('/login');
                    });
                },
            },

            {
                path: '/profile',
                name: 'profile',
                component: () => import('./pages/Admin/Profile'),
            },

            {
                path: '/tasks',
                name: 'admin-tasks',
                component: () => import('./pages/Admin/Tasks'),
            },

            {
                path: '/rewards',
                name: 'admin-rewards',
                component: () => import('./pages/Admin/Rewards'),
            },
            {
                path: '/translate',
                name: 'translations',
                component: () => import('./pages/Admin/Translations'),
            },
            {
                path: '/language',
                name: 'languages',
                component: () => import('./pages/Admin/Languages'),
            },
            {
                path: '/family-stat',
                name: 'family-stat',
                component: () => import('./pages/Admin/FamilyStat'),
            },
            {
                path: '/family-item/:id',
                name: 'family-item',
                component: () => import('./pages/Admin/FamilyItem'),
            },
            {
                path: '/push-notifications',
                name: 'push-notifications',
                component: () => import('./pages/Admin/PushNotifications'),
            },
            {
                path: '/bundles',
                name: 'bundles-list',
                component: () => import('./pages/Admin/Bundle/Bundles'),
            },
            {
                path: '/currencies',
                name: 'currencies',
                component: () => import('./pages/Admin/Currencies'),
            },
            {
                path: '/version',
                name: 'version',
                component: () => import('./pages/Admin/Version'),
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./pages/Admin/Login")
    },
    {
        path: '/404',
        name: '404',
        component: () => import('./pages/404'),
    },
    {
        path: '/',
        name: 'entry',
        redirect: '/admin'
    }
];

// Init router
const router = new VueRouter({
    history: true,
    mode: 'hash',
    routes,
});

export default router
