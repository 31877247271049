import {api} from '../../utils/api'

export default {

    /**
     * Create reward
     * @param commit
     * @param payload
     */
    // eslint-disable-next-line no-unused-vars
    create({ commit }, payload) {

        // Create id
        payload.id = api.uuid();

        // Commit changes
        commit("SET_APPLICATIONS", payload);

        // Sync
        api.sync('patch', '/api/admin/exchange/update/' + payload.id, payload);

        // Create uuid
        return api.emptyPromise(payload);
    },

    /**
     * Update reward
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_APPLICATIONS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/exchange/update/' + payload.id, payload);
    },

    delete({commit}, application) {
        commit("DELETE_APPLICATIONS", application)

        return api.sync('delete', '/api/admin/exchange/delete/' + application.id);
    },

    redeem({commit}, payload) {
        return api.call(commit, 'post', '/api/admin/exchange/redeem/' + payload.member_id, payload, 'SET_REDEEM_APPLICATIONS');
    },

    loadCurrencies({commit}) {
        return api.call(commit,'get', '/api/admin/exchange/currencies/', null, "SET_CURRENCIES");
    },

    loadApplications({commit}, familyId) {
        return api.call(commit,'get', '/api/admin/exchange/applications/' + familyId, null, "SET_APPLICATIONS");
    },

    loadRedeemedApplications({commit}, familyId) {
        return api.call(commit,'get', '/api/admin/exchange/redeemed-applications/' + familyId, null, "SET_REDEEM_APPLICATIONS");
    },
    currencyUpdate({commit}, payload) {
        return api.call(commit,'patch', '/api/admin/exchange/currency/', payload, "SET_CURRENCIES");
    },

    currencyDelete({commit}, id) {
        return api.call(commit,'delete', '/api/admin/exchange/currency/' + id, null, "SET_CURRENCIES");
    }

}
