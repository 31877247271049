export default {

    /**
     * Get page title
     * @param state
     * @returns request
     */
    title: state => {
        // Get title
        return state.title;
    },

    /**
     * Get submenu
     * @returns {number}
     */
    toolbar: state => {

        // Return submenu
        return state.toolbar;
    },

    /**
     * Get var value
     * @param state
     * @returns request
     */
    var: state => (name) => {
        // Get title
        return state.vars[name];
    },

    /**
     * Get colors list
     * @returns {number}
     */
    colors: () => {

        // Return submenu
        return ["#003f5c70", "#ff000070", "#bc509070", "#ff636170", "#ffa60070"];
    }

}
