/**
 * Reward getters
 */
export default {

    // Get current reward
    get: state => (i) => {

        // Return current state
        return state.rewards[i];
    },

    // Get rewards list
    list: state => (payload) => {

        // Tasks list
        let tList = {};

        // Rearrange list
        Object.values(state.rewards).
        filter((rew) => (rew.group === payload.group ) ).
        sort((a, b) => (a.family_id ? a.family_id.length : 0) - (b.family_id ? b.family_id.length : 0)).
        map((i) => {

            // Get title
            let ttl = !i.family_id ? window.app.$t(i.title) : i.title;

            // Set title
            tList[ttl] = i;
        });

        // Return tasks list
        return Object.values(tList);
    },

    // Get tasks list for exact member
    memberList: state => (payload) => {

        // Return tasks list
        return Object.values(state.rewards).filter((reward) => reward.member_id === payload.member_id && reward.group == payload.group);
    },

    // Get rewards template list
    templateList: state => (group) => {

        // Return rewards list
        return Object.values(state.rewards).filter((reward) => (reward.family_id === null && reward.group === group) );
    },

    // Get groups list
    groups: state => {

        // Return tasks list
        return state.groups;
    },

    /**
     * get group id
     * @param state
     * @returns {function(...[*]=)}
     */
    getRewardGroup: state => (id) =>
    {
        if(!state.rewards[id]) return "none";

        // Get task group
        return state.groups[state.rewards[id].group];
    },

    /**
     * Get rewards groups
     */

    getRewardGroupsList: state => (id) =>
    {
        // Get reward
        let reward = state.rewards[id];

        // No reward - no groups
        if(!reward) return "";

        // Result string
        let res = [];
        for(let g in reward.groups)
        {
            // Get score
            let sc = reward.groups[g];

            // Add result
            if(sc>0) res.push(window.app.$t("ttl"+g)+": "+sc);
        }

        // Return string
        return res.join(", ");
    }

}
