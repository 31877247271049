import Vue from 'vue'
export default {


    /**
     * Set rewards
     * @param state
     * @param rewards
     * @constructor
     */
    SET_REWARDS(state, rewards) {

        // Nothing to do
        if (!rewards) return;

        // Create rewards if absent
        if (!state.rewards) state.rewards = {};

        // Rewards can be a single reward or rewards list
        if (!Array.isArray(rewards)) rewards = [rewards];

        // Save each reward from list to state
        rewards.forEach(i => {

            // Create object if absent
            if (!state.rewards[i.id]) {
                // Set whole object
                Vue.set(state.rewards, i.id, i);
            } else {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for (let fld in i) if (i.hasOwnProperty(fld)) Vue.set(state.rewards[i.id], fld, i[fld]);
            }
        });
    },

    /**
     * Reset rewards
     * @param state
     * @param rewards
     * @constructor
     */
    RESET_REWARDS(state, rewards) {

        // Create rewards if absent
        state.rewards = {};

        // Nothing to do
        if (!rewards) return;

        // Rewards can be a single reward or rewards list
        if (!Array.isArray(rewards)) rewards = [rewards];

        // Save each reward from list to state
        rewards.forEach(i => {
            // Set whole object
            Vue.set(state.rewards, i.id, i);
        });
    },


    /**
     * Delete reward
     * @param state
     * @param reward
     * @constructor
     */
    DELETE_REWARDS(state, rewards) {

        // Rewards can be a single reward or rewards list
        if (!Array.isArray(rewards)) rewards = [rewards];

        // Save each reward from list to state
        rewards.forEach(i => {
            Vue.delete(state.rewards, i.id);
        });

    },


    /**
     * Set groups
     * @param state
     * @param groups
     * @constructor
     */
    SET_GROUPS(state, groups) {
        Vue.set(state, 'groups', groups);
    }
}
