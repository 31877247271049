export default {

    // install plugin
    install(Vue) {

        /**
         * Dispatch action
         * @param action
         * @param payload
         * @returns {Promise<unknown>}
         */
        Vue.prototype.$dispatch = function(action, payload) {
            return new Promise((resolve, reject) => {
                this.$store.dispatch(action, payload).then(res => {
                    // resolve promise
                    resolve(res);
                }).catch(err => {
                    // reject promise
                    reject(err);

                    // show error toast
                    //toast.notify(this, 'Error', err.message, 'danger');
                });
            });
        };

        /**
         * Getter
         * @param action
         * @param payload
         * @returns {*}
         */
        Vue.prototype.$get = function(action, payload) {

            if(this.$store.getters[action] === undefined)
            {
                console.log("Vuex method not found: "+action);
                return false;
            }

            // Get getters
            return payload !== undefined ? this.$store.getters[action](payload) : this.$store.getters[action];
        };

        /**
         * Unreact get
         * @param action
         * @param payload
         * @returns {*}
         */
        Vue.prototype.$unreactGet = function(action, payload=false) {

            // Get getters
            return this.$api.unreact(this.$get(action, payload));
        }

        /**
         * Obtain an url by uploaded image filename
         * @param  {string} filename Filename of the uploaded image
         * @return {string}          URL of uploaded image
         */
        Vue.prototype.$uploadedImageUrl = function(filename) {
            return `${process.env.VUE_APP_API_PATH}/uploads/media/uploadPhoto/${filename}`;
        };

        /**
         * Obtain an url by base icon filename
         * @param  {string} filename Filename of the uploaded image
         * @return {string}          URL of uploaded image
         */
        Vue.prototype.$baseIconUrl = function(filename) {
            return `${process.env.VUE_APP_API_PATH}/uploads/media/base_icons/${filename}`;
        };
    }
};
