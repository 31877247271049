import state from './moduleTranslationState.js'
import mutations from './moduleTranslationMutations.js'
import actions from './moduleTranslationActions.js'
import getters from './moduleTranslationGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
