// import store from '../../store/store'
import {api} from '../../utils/api'

export default {
    /**
     * Create member
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {
        // Sync
        return api.call(commit,'post', '/api/admin/push-notification/update/', payload, "SET_NOTIFICATIONS");
    },

    list({ commit }) {
        return api.call(commit,'get', '/api/admin/push-notification/list/', '', "SET_NOTIFICATIONS");
    },

    delete({ commit }, item) {
        commit('REMOVE_NOTIFICATIONS', item)
        return api.sync('delete', '/api/admin/push-notification/delete/' + item.id, '');
    }
}
