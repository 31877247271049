import {api} from "../../utils/api";

/**
 * Reward getters
 */
export default {

    // Get current reward
    getCurrencies: state => {

        // Return current state
        return state.currencies;
    },

    getCurrency: state => (id) => {
        let choiced = null
        state.currencies.forEach(item => {
            if (item.id === id) {
                choiced = item
            }
        })

        return choiced
    },

    getRateSteps: state => {
        return state.rateSteps
    },

    // Get rewards list
    applicationList: state => {
        return state.applications
    },

    // Get tasks list for exact member
    application: state => (id) => {
        return state.applications[id]
    },

    // Get tasks list for exact member
    redeemedApplication: state => (id) => {
        return state.redeemApplications[id]
    },

    redeemedApplicationList: state => (payload) => {
        return Object.values(state.redeemApplications).filter((item) => {
            // Check for member id
            return api.extractDate(item.client_time) === payload.date;
        });
    },

    // eslint-disable-next-line no-unused-vars
    memberApplications: state => (member_id) => {
        return Object.values(state.applications).filter(item => {
            return member_id === item.member_id
        }) || {}
    },

    getCurrencyByCode: state => (code) => {
        let choiced = null
        state.currencies.forEach(item => {
            if (item.code === code) {
                choiced = item
            }
        })

        return choiced
    }
}
