import {api} from '../../utils/api'

/**
 * Completedtask getters
 */
export default {

    // Get current completedtask
    get: state => (i) => {

        // Return current state
        return state.completedtasks[i];
    },

    // Get completedtasks list
    list: state => (payload) => {

        // Return completedtasks list
        return Object.values(state.completedtasks).filter((i) =>
            i.member_id === payload.member_id && api.extractDate(i.client_time) === payload.date
        );
    },

    // Get uncompleted tasks list for exact member
    memberList: state => (member_id) => {

        // Return completedtasks list
        return Object.values(state.completedtasks).filter((completedtask) =>
            (
                //isAdult &&
                //!store.getters['family/isLoggedInUser'](completedtask.member_id) &&
                completedtask.member_id === member_id &&
                completedtask.approved==-1
            ) );
    },


    // Get groups list
    all: state => {

        // Return completedtasks list
        return state.completedtasks;
    },

}
