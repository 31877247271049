import store from '../../store/store'
import {api} from '../../utils/api'

export default {

    /**
     * Create member
     * @param commit
     * @param payload
     */
    create({ commit }, payload) {

        // Create id
        payload.id = api.uuid();

        // Commit changes
        commit("SET_MEMBERS", payload);

        // Sync
        api.sync('patch', '/api/admin/member/' + payload.id, payload);

        // Create uuid
        return api.emptyPromise(payload);
    },

    /**
     * Update member
     * @param commit
     * @param payload
     */
    update({ commit }, payload) {

        // Commit changes
        commit("SET_MEMBERS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/member/' + payload.id, payload);
    },

    /**
     * Load member
     * @param commit
     * @param payload
     */
    load({ commit }, payload) {

        // Create uuid
        return api.call( commit,'get', '/api/admin/member/' + payload, null, "SET_MEMBERS");
    },

    /**
     * Load tasks from server
     */
    loadList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/member/' + familyID + '/list', null, "RESET_MEMBERS");
    },

    /**
     * Load tasks from server
     */
    loadAdminList({ commit }, familyID)
    {
        // Create uuid
        return api.call(commit,'get', '/api/admin/family/list-members/' + familyID, null, "SET_LIST_MEMBERS");
    },


    /**
     * Update member
     * @param commit
     * @param payload
     */
    updateCurrent({ commit }, payload) {

        // Get current member
        let curUser = store.getters['family/getCurrentUser'];

        // Patch payload
        payload.id = curUser.id;

        // Commit changes
        commit("SET_MEMBERS", payload);

        // Create uuid
        return api.sync('patch', '/api/admin/member/' + payload.id, payload);
    },


    /**
     * Delete member
     * @param commit
     * @param payload
     */
    delete({ commit }, payload) {

        // Commit changes
        commit("DELETE_MEMBERS", payload);

        // Create uuid
        return api.sync('delete', '/api/admin/member/' + payload.id);
    },

    /**
     * Earn points
     * @param commit
     * @param payload
     */
    earn({ commit }, payload) {

        // Commit changes
        commit("EARN_POINTS", {groups:payload.groups, member_id: payload.member_id, direction:1});

        // Create uuid
        return api.sync('patch', '/api/admin/member/' + payload.member_id,
            {
                points: store.getters["member/get"](payload.member_id).points
            });

    },

    /**
     * Spent points
     * @param commit
     * @param payload
     */
    spent({ commit }, reward) {

        // Commit changes
        commit("EARN_POINTS", {groups:reward.groups, member_id: reward.member_id, direction:-1});

        // Create uuid
        return api.sync('patch', '/api/admin/member/' + reward.member_id,
            {
                points: store.getters["member/get"](reward.member_id).points
            });
    },

    /**
     * Reset
     */
    reset({commit})
    {
        commit("RESET_MEMBERS", {});
    },

}
