import {api} from "../../utils/api";

export default {
    /**
     * Set title
     * @param commit
     * @param title
     * @returns {*|Promise|Promise<any>}
     */
    setTitle({ commit }, title) {
        commit("SET_TITLE", title);
    },

    /**
     * Set toolbar
     * @param commit
     * @param menu
     * @returns {*|Promise|Promise<any>}
     */
    setToolbar({ commit }, tb) {
        commit("SET_TOOLBAR", tb);
    },

    /**
     * Reset menu
     * @param commit
     * @param menu
     * @returns {*|Promise|Promise<any>}
     */
    reset({ commit }) {
        commit("SET_TITLE", "");
        commit("SET_TOOLBAR", {});
    },

    /**
     * Load avatars from server
     */
    loadBaseIcons({ commit })
    {
        return api.call(commit,'get', '/api/admin/base-icons', null, "SET_BASE_ICONS");
    },
}
