/**
 * Family state
 */

export default {

    // Default family id
    id: false,
    title: "No title",
    member_id: 0,
    families: null
}
