import Vue from 'vue'
export default {


    /**
     * Set members
     * @param state
     * @param data
     * @constructor
     */
    SET_MEMBERS(state, members) {

        // Nothing to do
        if (!members) return;

        // Create members if absent
        if (!state.members) state.members = {};

        // Tasks can be a single member or members list
        if (!Array.isArray(members)) members = [members];

        // Save each member from list to state
        members.forEach(i => {

            // Create object if absent
            if(!state.members[i.id])
            {
                // Set whole object
                Vue.set(state.members, i.id, i);
            }
            else
            {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for(let fld in i) if(i.hasOwnProperty(fld)) Vue.set(state.members[i.id], fld, i[fld]);
            }
        });
    },

    /**
     * Reset members
     * @param state
     * @param data
     * @constructor
     */
    RESET_MEMBERS(state, members) {

        // Reset list
        state.members = {};

        // Nothing to do
        if (!members) return;

        // Tasks can be a single member or members list
        if (!Array.isArray(members)) members = [members];

        // Save each member from list to state
        members.forEach(i => {
                // Set whole object
                Vue.set(state.members, i.id, i);
        });
    },


    SET_LIST_MEMBERS(state, members) {
        state.members = []
        Vue.set(state, 'members', members);
    },


    /**
     * Delete member
     * @param state
     * @param task
     * @constructor
     */
    DELETE_MEMBERS(state, members) {

        // Tasks can be a single member or tasks list
        if (!Array.isArray(members)) members = [members];

        // Save each member from list to state
        members.forEach(i => {
            Vue.delete(state.members, i.id);
        });

    },

    /**
     * Earn points
     * @param state
     * @param task
     * @constructor
     */
    EARN_POINTS(state, payload) {

        // Get member
        let member = state.members[payload.member_id];

        // Check for member
        if(member)
        {
            // Check for groups
            if(!member.points) member.points = {};

            // Add points
            for(let gr in payload.groups)
            {
                // Skip non properties
                // eslint-disable-next-line no-prototype-builtins
                if(!payload.groups.hasOwnProperty(gr)) continue;

                // Add points
                member.points[gr] = (member.points[gr] ? member.points[gr] : 0) + payload.direction * payload.groups[gr];
            }

            // Set object to store
            Vue.set(state.members, payload.member_id, member);
        }
    }
}
