import Vue from 'vue'
export default {

    /**
     * Set tasks
     * @param state
     * @param data
     * @constructor
     */
    SET_TASKS(state, tasks) {
        // Nothing to do
        if (!tasks) return;

        // Create tasks if absent
        if (!state.tasks) state.tasks = {};

        // Tasks can be a single task or tasks list
        if (!Array.isArray(tasks)) tasks = [tasks];

        // Save each task from list to state
        tasks.forEach(i => {

            // Create object if absent
            if (!state.tasks[i.id]) {
                // Set whole object
                Vue.set(state.tasks, i.id, i);
            } else {
                // Set only new values
                // eslint-disable-next-line no-prototype-builtins
                for (let fld in i) if (i.hasOwnProperty(fld)) Vue.set(state.tasks[i.id], fld, i[fld]);
            }
        });
    },


    /**
     * Reset tasks
     * @param state
     * @param tasks
     * @constructor
     */
    RESET_TASKS(state, tasks) {

        // Reset tasks
        state.tasks = {};

        // Nothing to do
        if (!tasks) return;

        // Tasks can be a single task or tasks list
        if (!Array.isArray(tasks)) tasks = [tasks];

        // Save each task from list to state
        tasks.forEach(i => {
            // Set whole object
            Vue.set(state.tasks, i.id, i);
        });
    },


    /**
     * Delete task
     * @param state
     * @param task
     * @constructor
     */
    DELETE_TASKS(state, tasks) {

        // Tasks can be a single task or tasks list
        if (!Array.isArray(tasks)) tasks = [tasks];

        // Save each task from list to state
        tasks.forEach(i => {
            Vue.delete(state.tasks, i.id);
        });

    },

    /**
     * Set groups
     * @param state
     * @param groups
     * @constructor
     */
    SET_GROUPS(state, groups) {
        Vue.set(state, 'groups', groups);
    }
}
