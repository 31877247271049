import state from './moduleMemberState.js'
import mutations from './moduleMemberMutations.js'
import actions from './moduleMemberActions.js'
import getters from './moduleMemberGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
