import state from './moduleDoneRewardState.js'
import mutations from './moduleDoneRewardMutations.js'
import actions from './moduleDoneRewardActions.js'
import getters from './moduleDoneRewardGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
